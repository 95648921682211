.rootLoading {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff00;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 170px;
    position: relative;
    margin: 0px;
    padding: 0px;
}

.circle {
    border: 5px transparent solid;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 69%;
}

.cyan {
    top: 0px;
    border-top: 5px #00ffff solid;
    animation-delay: 4s;
    animation: moveCyan 1.5s infinite;
}

.cyan:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #00ffff;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px #00ffff;
}

.cyan:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #00ffff;
    top: 10px;
    left: 11px;
    border-radius: 69%;
}

.magenta {
    left: 0px;
    bottom: 0px;
    border-top: 5px #ff00ff solid;
    animation: moveMagenta 1.5s infinite;
}

.magenta:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #ff00ff;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px #ff00ff;
}

.magenta:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #ff00ff;
    top: 10px;
    left: 11px;
    border-radius: 69%;
}

.yellow {
    right: 0px;
    bottom: 0px;
    border-top: 5px #ffff00 solid;
    animation: moveYellow 1.5s infinite;
}

.yellow:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #ffff00;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px #ffff00;
}

.yellow:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #ffff00;
    top: 10px;
    left: 11px;
    border-radius: 69%;
}

@keyframes moveCyan {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes moveMagenta {
    0% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(600deg);
    }
}

@keyframes moveYellow {
    0% {
        transform: rotate(120deg);
    }

    100% {
        transform: rotate(480deg);
    }
}

.loadingP {
    font-family: roboto, sans-serif;
    width: 275px;
    position: absolute;
    top: 220px;
    font-size: 1.5em;
    letter-spacing: 0.15em;
    font-weight: 100;
    filter: drop-shadow(2px 2px 15px #ffffff70);
}