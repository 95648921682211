
.configButton {
  opacity: 0 !important;
  transition: all 0.2s linear !important;
}

.showOptions:hover .configButton {
  opacity: 1 !important;
}

.react-flow .react-flow__handle {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #787878;
  border-color: rgb(255 255 255);
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

*:focus {
  outline: none;
}


#Teste>div.MuiBox-root.css-v2kfba>div>div.react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
}