.image-container {
    position: relative;
    cursor: pointer;
}

.image-container img {
    display: block; /* Garante que não haja espaço abaixo da imagem */
    border-radius: 8px; /* Opcional: ajuste o estilo da imagem */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escurecido */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0; /* Começa invisível */
    transition: opacity 0.3s ease; /* Transição suave */
}

.image-container:hover .overlay {
    opacity: 1; /* Torna visível ao passar o mouse */
}

.message {
    color: white;
    font-size: 18px; /* Tamanho da fonte da mensagem */
    text-align: center;
}
