button#messageActionsButton {
    visibility: hidden !important;
}

div.componentChat:hover button#messageActionsButton {
    visibility: visible !important;
}

.playAudioTag {
    display: none !important;
}

.render_audio:hover .playAudioTag {
    display: block !important;
}

.avatarUser {
    display: block !important;
}

.render_audio:hover .avatarUser {
    display: none !important;
}