@media (max-width: 900px) {
    .ticketsWrapper {
        border-radius: 5px;
    }
}

.ticketsWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    flex-direction: column;
    overflow: hidden;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
