.timeoutdanger {
    -webkit-animation: pulse 500ms infinite alternate;
    animation: pulse 500ms infinite alternate;
}

@-webkit-keyframes pulse {
    0% {
        background-color: red;
    }

    100% {
        background-color: yellow;
    }
}

@keyframes pulse {
    0% {
        background-color: red;
    }

    100% {
        background-color: yellow;
    }
}

.timeoutinfo {
    background-color: yellow !important;
}

.timeoutok {
    background-color: #00ab55 !important;
}

.colorChip {
    margin-right: 5px;
    height: 25px !important;
    min-width: 85px;
    display: flex;
    align-items: center;
    font-size: 10px;
    border-radius: 25px !important;
    color: #000000;
    flex-direction: row-reverse;
}


.wave {
    margin-top: -15px;
    margin-left: -15px;
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    left:50%; 
    top:50%; 
    transform: translate(-50%, - 50%)
  }
  .wave:before, .wave:after {
    position: absolute;
    content: '';
    border: 4px solid #fe0000;
    opacity: 1;
    border-radius: 50%;
    left:50%; 
    top:50%; 
    transform: translate(-50%, - 50%);
    animation: wave 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .wave:after {
    animation-delay: -0.4s;
  }
  @keyframes wave {
    0% {    
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }