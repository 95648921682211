.rhap_additional-controls {
    display: none !important;
}

.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
    display: none !important;
}

.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
    display: none !important;
}

.rhap_volume-controls {
    display: none !important;
}


.rhap_controls-section {
    display: flex;
    flex: initial !important;
    justify-content: space-between;
    align-items: center;
}